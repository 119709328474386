<template>
    <div class="row" id="contenedorFooter" style="margin-left: 0px!important;z-index: 100">
        <div class="col col-12 col-lg-6 pl-3 pb-2 pr-0" id="stgo" style="text-align: left!important;color:#FFF!important;">
            <h3 style="font-size:16px!important;" class="enlaceLocal mt-1 mb-0">
                <b-icon scale="1.2" icon="geo-alt-fill" class="ml-1 mr-2 mt-1"></b-icon>
                <b-link class="enlaceLocal mb-1" style="font-size:16px;" href="https://www.google.com/maps/place/Beaucheff,+Talcahuano,+B%C3%ADo+B%C3%ADo/@-36.7246457,-73.1298112,14.08z/data=!4m5!3m4!1s0x9668350d397e2eeb:0xffe080760fc86c44!8m2!3d-36.726893!4d-73.1123741" target="_blank">Av. Beaucheff 1325</b-link>
            </h3>
            <h3 style="font-size:16px;" class="mt-1 mb-0 telefono">
            <b-icon icon="telephone-fill" class="ml-1 mr-2 mt-1" scale="1.2"></b-icon>+56 9 5851 7834
            </h3>
            
        </div>
        <div class="col col-lg-6 col-12">
        </div>
    </div>
</template>

<script>
export default {
    name:'foot'
}
</script>

<style>
#contenedorFooter{
    position: fixed;
    bottom:0px!important;
    width:100%!important;
    background-color: #000000d4!important;
    border-top: 5px solid rgb(221, 50, 50)!important;
}
.enlaceLocal{
    color: #FFF!important;
}
</style>