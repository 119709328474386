<template>
  <div class=" fondo2">
    <div class="container-fluid pb-3">
      <div v-for="(t, index) in lista" :key="index" class="row pt-3 ml-0" style="top:70px; width:100%;">
        <div class="col col-12 col-lg-3 col-xs-12 col-md-1 col-md-1 p-0"></div>
        <div class="col col-12 col-lg-6 col-xs-12 col-md-10 col-md-10 pl-4 pr-4 pt-4" style="    background-color: rgb(0 0 0 / 61%) !important;">
          <div style="display:inline-block; width:100%;">
            <h3 class="titulo">{{t.titulo}}</h3>
            <p class="texto pb-1">{{t.texto}}</p>
          </div>
          <Deslizador :lista="t.imgs"></Deslizador>
        </div>
        <div class="col col-12 col-lg-3 col-xs-12 col-md-1 col-md-1 p-0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Deslizador from '../components/deslizador'
import Nav from '../components/navegador' 
import Foot from '../components/footer' 
export default {
  name: 'Home', 
  data(){
    return{ 
      lista:[{
        titulo: 'Automatización y Programación PLC',
        texto:'Estamos en constante actualización con las últimas tecnologías para la automatización de los procesos industriales y estar siempre a la vanguardia de los desafíos tecnológicos.',
        imgs:['automatizacion1', 'automatizacion2']
      },
      {
        titulo: 'Telemetría',
        texto:'Desarrollo Sistemas de adquisición de datos que proporcionen información en línea del estado de operación de plantas industriales, generando bases de datos y alarmar al teléfono móvil.',
        imgs:['montaje2']
      },
      {
        titulo: 'Integración',
        texto:'Realizamos automatización e integración de sistemas de bombeo, control de estanques, control de temperatura.',
        imgs:['integracion1', 'integracion2', 'integracion3', 'integracion4']
      },
      {
        titulo: 'Armado de tableros',
        texto:'Contamos con un espacio físico para desarrollar sus proyectos.',
        imgs:['armado1', 'armado2']
      }]
    }
  },
  components: {
    Nav, Deslizador, Foot
  }
}
</script>

<style>
 .col, .row {
   background: transparent!important;
 }
.fondo2{
  padding-bottom: 10px;
  background-color: transparent!important;
}
img{
  width: 100%;
}
.titulo{
  font-size:50px; color: #FFF; text-align:left; 
}
.texto{
  font-size:30px; color: #FFF; text-align: left;
}


@media(min-width:992px){
  .titulo{
  font-size:30px!important; text-align:left; 
}
.texto{
  font-size:18px!important; text-align:left;
}
  .imgSliderBajo{
    height: 470px!important;
  }
  .slider{
    height: 500px!important;
  }
}



@media(min-width:768px) and (max-width:991px){
  .titulo{
  font-size:30px!important; text-align:left; 
}
.texto{
  font-size:18px!important; text-align:left;
}
  .imgSliderBajo{
    height: 470px!important;
  }
  .slider{
    height: 500px!important;
  }
}
@media(min-width:576px) and (max-width:767px){
  .titulo{
  font-size:30px!important; text-align:left; 
}
.texto{
  font-size:18px!important; text-align:left;
}
  .imgSliderBajo{
    height: 455px!important;
  }
  .slider{
    height: 490px!important;
  }
}
@media  (min-width:480px) and (max-width: 575px) {
  .titulo{
  font-size:28px!important; text-align:left; 
}
.texto, .textoCarta{
  font-size:16px!important; text-align:left;
}
  .imgSliderBajo{
    height: 390px!important;
  }
  .slider{
    height: 420px!important;
  }
}
@media  (min-width:410px) and (max-width: 479px) {
  .titulo{
  font-size:25px!important; text-align:left; 
}
.texto, .textoCarta{
  font-size:16px!important; text-align:left;
}
  .imgSliderBajo{
    height: 370px!important;
  }
  .slider{
    height: 400px!important;
  }
  .enlaceLocal, .telefono{
    font-size: 14px!important;
  }
}
@media  (min-width:350px) and (max-width: 409px) {
  .titulo{
    font-size:25px!important; text-align:left; 
  }
  .texto, .textoCarta{
    font-size:14px!important; text-align:left;
  }
  .imgSliderBajo{
    height: 290px!important;
  }
  .slider{
    height: 320px!important;
  }
  .enlaceLocal, .telefono{
    font-size: 12px!important;
  }
}
@media  (max-width:350px) {
  .titulo{
  font-size:22px!important; text-align:left; 
}
.texto, .textoCarta{
  font-size:14px!important; text-align: left;
}
  .imgSliderBajo{
    height: 270px!important;
  }
  .slider{
    height: 300px!important;
  }
  .enlaceLocal, .telefono{
    font-size: 10px!important;
  }
}
</style>