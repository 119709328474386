<template>
<div>
  <div class="container-fluid fondo2">
    <div class="container">
        <!-- <h3 class="titulo pt-3 ml-0">{{titulo}}</h3> -->
        <div class="row">
        <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
            <b-card-group deck class="pt-3 pb-3">
                <b-card header="Hora Hombre" header-tag="h4">
                    <b-card-text class="textoCarta" style="text-align:justify!important;">{{texto1}}</b-card-text>
                    <template #footer>
                    <b-icon icon="people-fill" scale="2.4" aria-hidden="true"></b-icon>
                    </template>
                </b-card>

                <b-card header="Automatización" header-tag="h4">
                    <b-card-text class="textoCarta" style="text-align:justify!important;">{{texto2}}</b-card-text>
                    <template #footer>
                    <b-icon icon="bezier2" scale="2.4" aria-hidden="true"></b-icon>
                    </template>
                </b-card>
            </b-card-group>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
            <b-card-group deck class="pt-3 pb-3">
                <b-card header="Montajes" header-tag="h4">
                    <b-card-text class="textoCarta" style="text-align:justify!important;">{{texto3}}</b-card-text>
                    <template #footer>
                    <b-icon icon="layers-half" scale="2.4" aria-hidden="true"></b-icon>
                    </template>
                </b-card>

                <b-card header="Supervisión" header-tag="h4">
                    <b-card-text class="textoCarta" style="text-align:justify!important;">{{texto4}}</b-card-text>
                    <template #footer>
                    <b-icon icon="person-check-fill" scale="2.4" aria-hidden="true"></b-icon>
                    </template>
                </b-card>
            </b-card-group>
        </div>
        </div>
        <div class="row">
        <div class="col col-lg-3 col-md-12 col-sm-12 col-12"></div>
        <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
            <b-card-group deck class="pt-0 pb-3">
                <b-card header="Otros Servicios" header-tag="h4">
                    <b-card-text class="textoCarta" style="text-align:justify!important;">
                        <ul class="mb-0 pl-2">
                            <li>Inspección Técnica Eléctrica y Electrónica.</li>
                            <li>Montajes de Máquinas Productivas.</li>
                            <li>Asesoramientos Eléctricos.</li>
                            <li>Diseño y Construcción de Tableros Eléctricos.</li>
                            <li>Diseño y Construcción de Mallas de Tierra.</li>
                            <li>Diseño y Construcción de Salas Eléctricas.</li>
                        </ul>
                    </b-card-text>
                    <template #footer>
                    <b-icon icon="three-dots" scale="3" aria-hidden="true"></b-icon>
                    </template>
                </b-card>
            </b-card-group>
        </div>
        <div class="col col-lg-3 col-md-12 col-sm-12 col-12"></div>
        </div>
    </div> 
  </div>
</div>
</template>

<script>
import Nav from '../components/navegador'
export default {
  name: 'Home', 
  data(){
    return{
      textoMision:'Desarrollar e integrar soluciones a la medida que permitan a nuestros clientes optimizar sus procesos productivos de forma eficiente, segura, sustentable y de calidad.',
      textoVision:'Ser una empresa líder en el desarrollo de soluciones integrales de ingeniería para la industria, estableciendo relaciones duraderas y de confianza con nuestros clientes logrando un reconocimiento y prestigio sostenible a través del tiempo.',
      titulo: 'Nuestros Servicios',
      texto1:"Prestamos el servicio de Hora Hombre, con excelentes profesionales del área eléctrica y electrónica.",
      texto2:"Realizamos procesos de Automatización para hacer mas productivos los procesos de las indústrias.",
      texto3:"Nuestra nueva área de negocio, que se enfoca en la ejecución de proyectos en altura.",
      texto4:"Realizamos Supervisión de proyectos eléctricos y electrónicos en Chile como en el extranjero.",
    }
  },
  components: {
    Nav
  }
}
</script>

<style>
 .col, .row {
   background: transparent!important;
 }
.fondo{
  height: auto;
  background-size: cover;
}
.fondo2{
  height: auto;
  background-size: cover;
}
img{
  width: 100%;
}
.titulo{
  font-size:50px; color: #FFF; text-align:left; 
}
.texto{
  font-size:25px; color: #FFF; text-align: justify;
}
h4.card-header{
  background-color: rgba(45, 102, 207, 0.9)!important;color: #FFF;
}
div.card{
  background-color:  #000000b1!important;color: #FFF;
}
</style>