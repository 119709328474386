<template>
<b-container fluid class="pl-0 pr-0">
  <slider style="width:auto; height:460px;backgrund-color:transparent!important;"
    :interval="7000"
    :speed="1500"
    :control-btn="false"
    :stopOnHover="false"
    animation="normal"
    :indicators="false">
  <slider-item v-for="(i, index) in lista" :key="index" style="">
<div>
<img class="imgSliderBajo" style="width:auto; border: 1px solid #000!important;" height="450" :src="require('../assets/' + i + '.jpg')" alt="">
<div class="row pt-5" style="top:70px;">
  <div class="col col-lg-2 col-md-1"></div>
  <div class="col col-lg-8 col-md-10">
    <h3 class="titulo">{{i.titulo}}</h3>
    <p class="texto">{{i.texto}}</p>    
  </div>
  <div class="col col-lg-2 col-md-1"></div>

</div>
</div>



  </slider-item>
</slider>
</b-container>
</template>

<script>
export default {
    name:'slide',
  data() {
    return {
      // lista: [
      //   { enlace: "../assets/1.jpg"},
      //   { enlace: "../assets/2.jpg"}]
    }
  },
  props:{
      lista: Array
  },
  methods: {
    hello($event) {
      console.log(`hello index: ${$event}`)
    },
  },
}
</script>

<style>
.titulo{
  font-size:50px; color: #FFF; text-align:justify; 
}
.texto{
  font-size:30px; color: #FFF; text-align: justify;
}
</style>