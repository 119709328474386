<template>
  <div>
    <ModalContacto ref="modalContacto"></ModalContacto>
    <b-navbar class="pl-2" toggleable="lg" type="dark" variant="transparent" style="border-bottom:2px solid rgb(221, 50, 50)!important;">
      <b-navbar-brand style="background-color:#ffffff00 !important;" class="mt-0 mb-0" to="/">
        <b-img :src="require('../assets/logo_bg.png')" style="height:70px!important; width:90px!important;"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
  
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav style="text-align: left!important;">
          <b-nav-item class="mr-3 mt-1 mb-2 enlace" to="/">INICIO</b-nav-item>
          <b-nav-item class="mr-3 mt-1 mb-2 enlace" to="/nosotros">SOBRE NOSOTROS</b-nav-item>
          <b-nav-item class="mr-3 mt-1 mb-2 enlace" to="/servicios">NUESTROS SERVICIOS</b-nav-item>
          <b-nav-item class="mr-3 mt-1 mb-2 enlace" v-b-modal.modal-center>CONTACTANOS</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import ModalContacto from './modalContacto'
export default {
    name:'navbar',
    methods:{
        modalContact(){
    }
},
    components:{
      ModalContacto
    }
}
</script>

<style>
li.enlace > a{
  color: #FFF!important;
}
nav.navbar, #nomEmpresa{
  background-color: #000000a1!important;
}
</style>