<template>
  <div id="app">
    <div id='soloFondo' ></div>
<Nav></Nav>    
    <!-- <Deslizador></Deslizador> -->

    <router-view id="contenedorInicial"/>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from './components/footer'
// import Deslizador from './components/deslizador'
import Nav from './components/navegador'
window.addEventListener('resize', function(){
  var alturaFooter = document.getElementById('contenedorFooter').clientHeight
  console.log(alturaFooter)
  var altura = this.innerHeight
  // document.getElementById('contenedorInicial').style.height = `"${altura - 240}px"`
  document.getElementById('soloFondo').style.height = `${altura}px`
})
export default {
  components:{
    Nav, Foot
  },
  mounted(){
    var alturaFooter = document.getElementById('contenedorFooter').clientHeight
  console.log(alturaFooter)
    var altura = window.innerHeight
    document.getElementById('soloFondo').style.height = `${altura}px`
    // document.getElementById('contenedorInicial').style.height = `"${altura - 240}px"`
  }
}
</script>

<style>
#contenedorInicial{
margin-bottom: 60px!important;
box-sizing: unset;
height: auto;
}
#soloFondo
{
  width: 100%!important;
  background: url(./assets/2.jpg) no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
html{
  width: 100%;
}
</style>
