<template>
    <div>
        <b-modal body-bg-variant="dark" id="modal-center" centered title="Información de Contacto" hide-footer header-text-variant="light">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-2" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Nombre o Razón Social"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                 :disabled="desactivado"
                type="email"
                autocomplete="off"
                placeholder="Correo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.fono"
                type="number"
                 :disabled="desactivado"
                autocomplete="off"
                placeholder="Teléfono (Opcional)"
                required
              ></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-3" label-for="input-3">
              <b-form-textarea
               :disabled="desactivado"
                rows="5"
                style="max-height:200px!important;"
                id="input-3"
                v-model="form.text"
                placeholder="Motivo"
                required
                @keyup.enter="onSubmit"
              ></b-form-textarea>
            </b-form-group>
            <div class="text-right">
              <b-button class="mr-2" type="reset" variant="danger">Limpiar</b-button>
              <b-button :disabled="desactivado" type="submit" style="font:bold!important;" variant="primary">Enviar</b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal centered body-bg-variant="dark" hide-footer size="sm" hide-header id="modal-mensajeEnviado">
          <div class="text-center mt-2">
            <b-icon :icon="icono" scale="3" variant="success"></b-icon>
          </div>
          <div class="text-center pt-3">
            <h4 class="mt-0" style="color: #FFF!important;">{{mensaje}}</h4>
          </div>
          <div class="text-right pt-2">
            <b-button variant="success" @click="hideModal">Entendido!</b-button>
          </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
  export default {
    name:'ModalContacto',
    data() {
      return {
        icono:'',
        desactivado:false,
        mensaje:'',
        form: {
          email: '',
          name: '',
          text: '',
          fono: '',
        },
        show: true
      }
    },
    methods: {
      onSubmit(event) {
        this.desactivado = true
        event.preventDefault()
        console.log(JSON.stringify(this.form))
        if(this.form.name != '' && this.form.email != '' && this.form.text != ''){
          const config = {
            'Content-Type': 'application/json'
          }
          axios.post('https://jgs-montajes.herokuapp.com/enviar-correo', this.form, config).then(function(resp){
            this.form.email = ''
            this.form.name = ''
            this.form.text = ''
            this.form.fono = ''
            this.$bvModal.hide('modal-center')
            console.log(resp.data)
            this.icono = resp.data.icono
            this.mensaje = resp.data.message
            this.$bvModal.show('modal-mensajeEnviado')
          }.bind(this))
        }
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.text = ''
        this.form.fono = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      hideModal(){
        this.desactivado = false
        this.$bvModal.hide('modal-mensajeEnviado')
      }
    },
    watch:{
      'form.name':function(nv, ov){
        function capitalize(nv) {
          return nv.charAt(0).toUpperCase() + nv.slice(1).toLowerCase();
        }
        this.form.name = nv.split(' ').map(capitalize).join(' ');
      },
      'form.email':function(nv, ov){
        function capitalize(nv) {
          return nv.toLowerCase();
        }
        this.form.email = nv.split('').map(capitalize).join('');
      },
    }
  }
</script>

<style>
#modal-center___BV_modal_header_{
  background-color: rgba(45, 102, 207, 0.9)!important;
  border-bottom: 0px!important;
}
#input-1, #input-2, #input-3{
  background-color: #ffffffed!important;
  color: #000;
}
#modal-center___BV_modal_content_,
#modal-mensajeEnviado___BV_modal_content_,
#modal-center___BV_modal_header_{
  border-radius: 0px;
}
#input-group-1__BV_label_,
#input-group-2__BV_label_,
#input-group-3__BV_label_{
  margin-bottom: 0px!important;
  font-style: italic!important;
}
</style>